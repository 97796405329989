<template>
  <input
    :class="inpClass || 'input'"
    type="text"
    v-bind="{ ...$attrs, ...$props }"
    placeholder="Cсылка на аккаунт"
    :value="value"
    v-on:input="updateValue($event.target.value)"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  props: ['value', 'inpClass'],
  methods: {
    updateValue(value) {
      const match = value.match(
        /(https?:\/\/)?(www\.)?(instagram\.com\/?)?@?(?<instaname>[a-zA-Z0-9._]+)\/?/
      );
      if (match) {
        value = match.groups.instaname;
      }
      value = value.replace(/[^0-9a-z_.]/gi, '');
      console.log(value);
      this.$emit('input', value);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.input::-webkit-input-placeholder {
  color: lightgray !important;
  opacity: 0.3;
}
</style>
