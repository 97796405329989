<template>
  <b-modal v-model="show" hide-footer :title="title">
    <b-form v-if="step === 1" @submit="instaname ? (step += 1) : null">
      <p>Напишите ваш аккаунт в этом поле без @. Например, korotylka</p>
      <div>
        <div class="mx-auto w-75">
          <instaname-input v-model="instaname" style="margin: 0 auto" class="w-100 mx-auto my-3" />
        </div>
      </div>
      <div class="w-100 text-center">
        <b-button :disabled="!instaname" variant="yellow" class="px-4 py-auto" type="submit"
          >Далее</b-button
        >
      </div>
    </b-form>
    <div v-else-if="step === 2 && mode == 'customers'">
      <p>Скопируйте это сообщение и разошлите всем рекламодателям, которых вы рекламировали.</p>
      <div class="text-muted border p-2 my-3 rounded" ref="message-container">{{ message }}</div>
      <div class="d-flex justify-content-around w-100">
        <b-button variant="outline-secondary" @click="clickShow">Показать отзывы</b-button>
        <b-button variant="yellow" @click="copy()">Скопировать</b-button>
      </div>
    </div>
    <div v-else-if="step === 2 && mode == 'portfolio'">
      <p>
        По ссылке ниже находятся отзывы только на ваш аккаунт. Вы можете скопировать ссылку и
        отправить её рекламодателям в качестве портфолио. На ваш аккаунт было оставлено
        {{ reviewsCount }} отзывов
      </p>
      <div class="text-muted border p-2 my-3 rounded" ref="message-container">
        {{ reviewsLink }}
      </div>
      <div class="d-flex justify-content-around w-100">
        <b-button variant="outline-secondary" @click="clickShow">Показать отзывы</b-button>
        <b-button variant="yellow" @click="copy()">Скопировать</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import InstanameInput from '../forms/InstanameInput.vue';
import reviewsApi from '../../api/reviews';

export default {
  components: {
    InstanameInput,
  },
  data: () => ({
    step: 1,
    instaname: null,
    reviewsCount: 0,
  }),
  watch: {
    step() {
      if (this.step === 2 && this.mode === 'portfolio') this.getReviewsCount();
    },
    show(value) {
      if (!value) this.reset();
    },
  },
  computed: {
    ...mapState({
      mode: (state) => state.reviewsList.generateBloggerLinkModalMode,
    }),
    show: {
      get() {
        return this.$store.state.reviewsList.showGenerateBloggerLinkModal;
      },
      set(value) {
        this.toggleGenerateBloggerLinkModal({ value, mode: this.mode });
      },
    },
    message() {
      return `Здравствуйте. Ранее вы были у меня на рекламе, пожалуйста, оставьте на нее отзыв  здесь (это займет не более 3-х минут) —  https://easyprbot.com/#/reviews/new/${this.instaname}`;
    },
    title() {
      return {
        customers: 'Добавить отзыв в таблицу',
        portfolio: 'Показать отзывы на аккаунт',
      }[this.mode];
    },
    reviewsLink() {
      return `https://easyprbot.com/#/reviews/?advertiser=${this.instaname}`;
    },
  },
  methods: {
    ...mapMutations('reviewsList', ['toggleGenerateBloggerLinkModal']),
    reset() {
      this.instaname = '';
      this.step = 1;
    },
    copy() {
      this.$copyText(
        { customers: this.message, portfolio: this.reviewsLink }[this.mode],
        this.$refs['message-container']
      ).then(
        () => console.log('copy success'),
        (e) => {
          // eslint-disable-next-line
          alert('При копировании произошла ошибка');
          console.log(e);
        }
      );
    },
    async getReviewsCount() {
      const res = await reviewsApi.fnc(
        { actions: ['count'] },
        { moderation_status: 2, executor: this.instaname }
      );
      this.reviewsCount = res.result;
    },
    clickShow() {
      this.$router.push({ query: { advertiser: this.instaname } });
    },
  },
};
</script>

<style scoped>
.generate-blogger-link-modal {
  max-width: 300px;
}
</style>
